import React, { useState } from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IChoiceGroupOption,
  ChoiceGroup,
  IImageProps,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

const options: IChoiceGroupOption[] = [
  {
    key: "sea.mp3",
    text: "Morze",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "nature.mp3",
    text: "Natura",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "piano.mp3",
    text: "Pianino",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "wind.mp3",
    text: "Wiatr",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "fire.mp3",
    text: "Ognisko",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "bells.mp3",
    text: "Dzwonki",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "guitar.mp3",
    text: "Gitara",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "100px" } }}> {render!(props)}</Stack>
      );
    },
  },
];

export interface IBackgroundSelectionProps {
  onNext(backgroundSelection: string): void;
  step: number;
}

const choiceGroupStyles = {
  label: {
    display: "inline",
  },
  flexContainer: {
    columnGap: "1em",
    display: "inline-flex",
    flexDirection: "row",
    flexWrap: "wrap",
    maxWidth: "400px",
  },
};

const BackgroundSelection = (props: IBackgroundSelectionProps) => {
  const onlyWidth = useWindowWidth();
  const [background, setBackground] = useState<string>();

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Wybierz dźwięk tła</Text>
          <Text variant="xLarge">Krok {props.step}</Text>
          <ChoiceGroup
            options={options}
            styles={choiceGroupStyles}
            onChange={(
              ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              option: IChoiceGroupOption | undefined
            ) => setBackground(option?.key)}
            label="Dźwięk tła"
            required={true}
          />
          <Stack
            horizontalAlign="center"
            styles={{ root: { paddingBottom: "30px" } }}
          >
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!background}
              onClick={() => props.onNext(background || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Wybierz dźwięk tła</Text>
            <Text variant="xLarge">Krok {props.step}</Text>
            <ChoiceGroup
              options={options}
              styles={choiceGroupStyles}
              onChange={(
                ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                option: IChoiceGroupOption | undefined
              ) => setBackground(option?.key)}
              label="Dźwięk tła"
              required={true}
            />
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!background}
              onClick={() => props.onNext(background || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default BackgroundSelection;
