import React, { useState } from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IImageProps,
  Checkbox,
  ITextStyles,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { BodyPart } from "../../Models";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

const ExplanationStyles: ITextStyles = {
  root: {
    marginBottom: "30px",
  },
};

const all: BodyPart[] = [
  { id: "head", value: "Głowa" },
  { id: "arms", value: "Ręce" },
  { id: "legs", value: "Nogi" },
];

export interface ITimeSelectionProps {
  onNext(bodyParts: string[]): void;
  isShort: boolean;
  step: number;
}

const TenseSelection = (props: ITimeSelectionProps) => {
  const onlyWidth = useWindowWidth();
  const [bodyParts, setBodyParts] = useState<string[]>([]);

  const onChange = React.useCallback(
    (id: string, checked: boolean): void => {
      if (!checked && bodyParts.includes(id)) {
        setBodyParts([...bodyParts.filter((a) => a !== id)]);
      }
      if (checked && !bodyParts.includes(id)) {
        setBodyParts([...bodyParts, id]);
      }
    },
    [bodyParts]
  );

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Wybierz afirmacje</Text>
          <Text variant="xLarge">Krok {props.step}</Text>
          <Text styles={ExplanationStyles}>
            Wybierz dwie części ciała, na których chcesz się skupić podczas
            napinania mięśni.
          </Text>
          <>
            {all.map(({ id, value }) => {
              return (
                <Checkbox
                  key={id}
                  label={value}
                  checked={bodyParts.includes(id)}
                  styles={{ root: { width: "200px" } }}
                  onChange={(
                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean
                  ) => onChange(id, checked!)}
                />
              );
            })}
          </>
          <Stack
            horizontalAlign="center"
            styles={{ root: { paddingBottom: "30px" } }}
          >
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={bodyParts.length !== 2}
              onClick={() => props.onNext(bodyParts || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Wybierz afirmacje</Text>
            <Text variant="xLarge">Krok {props.step}</Text>
            <Text styles={ExplanationStyles}>
              Wybierz dwie części ciała, na których chcesz się skupić podczas
              napinania mięśni.
            </Text>
            <Stack horizontal>
              <Stack>
                {all.map(({ id, value }) => {
                  return (
                    <Checkbox
                      key={id}
                      label={value}
                      checked={bodyParts.includes(id)}
                      styles={{ root: { width: "200px" } }}
                      onChange={(
                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                        checked?: boolean
                      ) => onChange(id, checked!)}
                    />
                  );
                })}
              </Stack>
            </Stack>
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={bodyParts.length !== 2}
              onClick={() => props.onNext(bodyParts || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default TenseSelection;
