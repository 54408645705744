import {
  CreateFromPath,
  CreateSilence,
  CreateSilence10s,
  CreateSilence30s,
  CreateSilence5s,
  folder,
  randomNumberInRange,
  sortRandom,
} from "./GeneratorHelper";
import { AudioModel } from "./../Models";

const createExerciseFor = (
  duration: string,
  mainFolderName: string,
  folderNames: string[],
  introName: string,
  exerciseName: string
) => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/tensionExercise/${mainFolderName}/intro/${introName}${randomNumberInRange(
        1,
        2
      )}.wav`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence5s()],
      [CreateSilence5s()]
    ),
  ];
  let parts = sortRandom(folderNames).slice(0, duration === "short" ? 3 : 4);
  for (let index = 0; index < parts.length; index++) {
    const element = parts[index];
    createdVoices = [
      ...createdVoices,
      CreateFromPath(
        `${folder}/tensionExercise/${mainFolderName}/exercise/${element}/${exerciseName}${randomNumberInRange(
          1,
          2
        )}.wav`
      ),
    ];
    createdVoices = [
      ...createdVoices,
      ...CreateSilence(
        duration,
        [CreateSilence5s()],
        [CreateSilence10s()],
        [CreateSilence30s()]
      ),
    ];
  }
  return createdVoices;
};

export const TenseGenerator = (
  duration: string,
  bodyParts: string[]
): AudioModel[] => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/tensionExercise/intro/ti${randomNumberInRange(1, 3)}.wav`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence5s()],
      [CreateSilence10s()]
    ),
  ];

  if (bodyParts.includes("head")) {
    createdVoices = [
      ...createdVoices,
      ...createExerciseFor(
        duration,
        "head",
        ["brows", "eyes", "mounth", "tongue"],
        "hi",
        "hb"
      ),
    ];
  }
  if (bodyParts.includes("arms")) {
    createdVoices = [
      ...createdVoices,
      ...createExerciseFor(
        duration,
        "arms",
        ["elbows", "fingers", "hand", "shoulders"],
        "ai",
        "ae"
      ),
    ];
  }
  if (bodyParts.includes("legs")) {
    createdVoices = [
      ...createdVoices,
      ...createExerciseFor(
        duration,
        "legs",
        ["bottoms", "heels", "knees", "toes"],
        "li",
        "lb"
      ),
    ];
  }

  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence30s()]
    ),
  ];
  return createdVoices;
};
