import React, { useState } from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IChoiceGroupOption,
  ChoiceGroup,
  IImageProps,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

const options: IChoiceGroupOption[] = [
  {
    key: "goodDay",
    text: "Dobry dzień",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "200px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "calmNight",
    text: "Spokojna noc",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "200px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "gratitude",
    text: "Wzmacniająca wdzięczność",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "200px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "inner",
    text: "Wewnętrzna harmonia",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "200px" } }}> {render!(props)}</Stack>
      );
    },
  },
  {
    key: "without",
    text: "Bez intencji",
    onRenderField: (props, render) => {
      return (
        <Stack styles={{ root: { width: "200px" } }}> {render!(props)}</Stack>
      );
    },
  },
];

export interface IIntentionSelectionProps {
  onNext(intentionSelection: string): void;
  step: number;
}

const choiceGroupStyles = {
  // label: {
  //   display: "inline",
  // },
  // flexContainer: {
  //   columnGap: "1em",
  //   display: "inline-flex",
  //   flexDirection: "row",
  //   flexWrap: "wrap",
  // },
};

const IntentionSelection = (props: IIntentionSelectionProps) => {
  const onlyWidth = useWindowWidth();
  const [intention, setIntention] = useState<string>();

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Wybierz intencję</Text>
          <Text variant="xLarge">Krok {props.step}</Text>
          <ChoiceGroup
            options={options}
            styles={choiceGroupStyles}
            onChange={(
              ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              option: IChoiceGroupOption | undefined
            ) => setIntention(option?.key)}
            label="Intencja"
            required={true}
          />
          <Stack
            horizontalAlign="center"
            styles={{ root: { paddingBottom: "30px" } }}
          >
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!intention}
              onClick={() => props.onNext(intention || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Wybierz intencję</Text>
            <Text variant="xLarge">Krok {props.step}</Text>
            <ChoiceGroup
              options={options}
              styles={choiceGroupStyles}
              onChange={(
                ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                option: IChoiceGroupOption | undefined
              ) => setIntention(option?.key)}
              label="Intencja"
              required={true}
            />
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!intention}
              onClick={() => props.onNext(intention || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default IntentionSelection;
