import {
  CreateFromPath,
  CreateSilence,
  CreateSilence10s,
  CreateSilence30s,
  CreateSilence5s,
  folder,
  randomNumberInRange,
} from "./GeneratorHelper";
import { AudioModel } from "./../Models";

export const GenerateBreathe = (
  duration: string,
  isLonger?: boolean
): AudioModel[] => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence10s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/breathe/instruction/brii${randomNumberInRange(1, 4)}.wav`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence10s(), CreateSilence10s()],
      [CreateSilence30s(), isLonger ? CreateSilence10s() : CreateSilence5s()],
      [
        CreateSilence30s(),
        CreateSilence10s(),
        isLonger ? CreateSilence30s() : CreateSilence5s(),
      ]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/breathe/inTheMiddle/m${randomNumberInRange(1, 3)}.wav`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence10s(), CreateSilence5s()],
      [CreateSilence10s(), isLonger ? CreateSilence30s() : CreateSilence10s()],
      [CreateSilence30s(), isLonger ? CreateSilence30s() : CreateSilence5s()]
    ),
  ];
  return createdVoices;
};
