import React, { useState } from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IChoiceGroupOption,
  ChoiceGroup,
  IImageProps,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

const Options: IChoiceGroupOption[] = [
  { key: "breathe", text: "Obserwacja oddechu" },
  { key: "breatheExercise", text: "Ćwiczenia oddechowe" },
  { key: "all", text: "Wszystkie" },
];

export interface ITimeSelectionProps {
  onNext(breatheSelection: string): void;
  duration: string;
  step: number;
}

const BreatheSelection = (props: ITimeSelectionProps) => {
  const onlyWidth = useWindowWidth();
  const [selected, setSelected] = useState<string>();

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Wybierz, co wolisz</Text>
          <Text variant="xLarge">Krok {props.step}</Text>
          <ChoiceGroup
            options={props.duration === "short" ? Options.slice(0, 2) : Options}
            onChange={(
              ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              option: IChoiceGroupOption | undefined
            ) => setSelected(option?.key)}
            label="Co wolisz?"
            required={true}
          />
          <Stack
            horizontalAlign="center"
            styles={{ root: { paddingBottom: "30px" } }}
          >
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!selected}
              onClick={() => props.onNext(selected || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Wybierz, co wolisz</Text>
            <Text variant="xLarge">Krok {props.step}</Text>
            <ChoiceGroup
              options={
                props.duration === "short" ? Options.slice(0, 2) : Options
              }
              onChange={(
                ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                option: IChoiceGroupOption | undefined
              ) => setSelected(option?.key)}
              label="Co wolisz?"
              required={true}
            />
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!selected}
              onClick={() => props.onNext(selected || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default BreatheSelection;
