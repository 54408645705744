import React from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IImageProps,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

export interface IIntroProps {
  onNext(): void;
}

const Intro = (props: IIntroProps) => {
  const onlyWidth = useWindowWidth();

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Generator medytacji</Text>
          <Text variant="xLarge">
            Już tylko kilka kroków dzieli Cię od stworzenia unikalnej
            medytacji...
          </Text>
          <Stack horizontalAlign="center">
            <Image
              {...imageProps}
              width="300px"
              height="auto"
              styles={{ root: { paddingTop: "20px", paddingBottom: "20px" } }}
            />
          </Stack>
          <Text>
            Wykorzystaj potęgę nowoczesnych technologii i spędź relaksujące
            chwile medytując.
          </Text>
          <Text>👍 W pełni za darmo</Text>
          <Text>👍 Bez żadnych reklam</Text>
          <Text>👍 Ty wybierasz, co będzie zawierała medytacja</Text>
          <Stack horizontalAlign="center">
            <PrimaryButton
              styles={ButtonStackStyles}
              onClick={() => props.onNext()}
            >
              Rozpocznij
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Generator medytacji</Text>
            <Text variant="xLarge">
              Już tylko kilka kroków dzieli Cię od stworzenia unikalnej
              medytacji...
            </Text>
            <Text>
              Wykorzystaj potęgę sztucznej inteligencji i spędź relaksujące
              chwile medytując.
            </Text>
            <Text>👍 W pełni za darmo</Text>
            <Text>👍 Bez żadnych reklam</Text>
            <Text>👍 Ty wybierasz co będzie zawierała medytacja</Text>
            <PrimaryButton
              styles={ButtonStackStyles}
              onClick={() => props.onNext()}
            >
              Rozpocznij
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default Intro;
