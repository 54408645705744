import React, { useState, useEffect } from "react";
import { AudioModel, LoaderValues } from "../Models";
import Player from "./Player";
import { Stack, Text, ITextStyles } from "@fluentui/react";
import ReactLoading from "react-loading";

const TextStyles: ITextStyles = {
  root: {
    marginTop: "20px",
    marginBottom: "20px",
  },
};

export interface IGeneratorProps {
  background: AudioModel;
  main: AudioModel[];
}

const Generator = (props: IGeneratorProps) => {
  const [isStopped, setIsStopped] = useState<boolean>(true);
  const [showLoader, setShowLoader] = useState<LoaderValues>(
    LoaderValues.GeneratingBackgroundSound
  );
  const [total, setTotal] = useState<number>(NaN);
  const [currentVoiceNumber, setCurrentVoiceNumber] = useState<number>(0);
  const [background] = useState({
    url: props.background.url,
    audio: props.background.audio
      ? props.background.audio
      : new Audio(props.background.url),
  });

  const [backgroundPlayer, setBackgroundPlayer] = useState({
    url: props.background.url,
    playing: false,
  });

  const [voices] = useState(
    props.main.map((m) => {
      return {
        url: m.url,
        audio: m.audio ? m.audio : new Audio(m.url),
      };
    })
  );

  const [voicePlayers, setVoicePlayers] = useState(
    props.main.map((m) => {
      return {
        url: m.url,
        playing: false,
      };
    })
  );

  useEffect(() => {
    setTimeout(function () {
      setTimeout(function () {
        setTimeout(function () {
          setShowLoader(LoaderValues.Done);
        }, 8_000);
        setShowLoader(LoaderValues.Downloading);
      }, 6_000);
      setShowLoader(LoaderValues.GeneratingVoice);
    }, 6_000);
    voices.forEach((source, i) => {
      source.audio.addEventListener("ended", () => {
        const newPlayers = [...voicePlayers];
        newPlayers[i].playing = false;
        if (voicePlayers.length > i + 1) {
          newPlayers[i + 1].playing = true;
          setCurrentVoiceNumber(i + 1);
        } else {
          setIsStopped(true);
        }
        setVoicePlayers(newPlayers);
      });
    });

    [background].forEach((source, i) => {
      source.audio.addEventListener("ended", () => {
        const newPlayers = [backgroundPlayer];
        newPlayers[i].playing = false;
        setBackgroundPlayer(newPlayers[0]);
      });
    });

    return () => {
      voices.forEach((source, i) => {
        source.audio.removeEventListener("ended", () => {
          const newPlayers = [...voicePlayers];
          newPlayers[i].playing = false;
          if (voicePlayers.length > i + 1) {
            newPlayers[i + 1].playing = true;
            setCurrentVoiceNumber(i + 1);
          } else {
            setIsStopped(true);
          }
          setVoicePlayers(newPlayers);
        });
      });

      [background].forEach((source, i) => {
        source.audio.removeEventListener("ended", () => {
          const newPlayers = [backgroundPlayer];
          newPlayers[i].playing = false;
          setBackgroundPlayer(newPlayers[0]);
        });
      });
    };
  }, []);

  useEffect(() => {
    voices.forEach((source, i) => {
      voicePlayers[i].playing ? source.audio.play() : source.audio.pause();
    });
  }, [voices, voicePlayers]);

  useEffect(() => {
    [background].forEach((source, i) => {
      backgroundPlayer.playing ? source.audio.play() : source.audio.pause();
    });
  }, [background, backgroundPlayer.playing]);

  useEffect(() => {
    if (!backgroundPlayer.playing && !isStopped) {
      setBackgroundPlayer({ ...backgroundPlayer, playing: true });
    }
    if (backgroundPlayer.playing && isStopped) {
      setBackgroundPlayer({ ...backgroundPlayer, playing: false });
    }

    let newPlayers = [...voicePlayers];
    newPlayers[currentVoiceNumber].playing = !isStopped;
    setVoicePlayers(newPlayers);
  }, [backgroundPlayer.playing, isStopped]);

  const toggle = () => {
    setBackgroundPlayer({ ...backgroundPlayer, playing: !isStopped });

    setIsStopped(!isStopped);
  };

  useEffect(() => {
    let totalDuration = 0;
    console.info("fired");
    console.info(
      "voices: " +
        JSON.stringify(
          voices.map((a) => {
            return [a, a.audio.duration];
          })
        )
    );
    for (let index = 0; index < voices.length; index++) {
      const element = voices[index].audio;
      totalDuration += element.duration;
    }
    let numberOfMinutes = Math.ceil(totalDuration / 60);
    setTotal(numberOfMinutes);
  }, [
    ...voices.map((a) => a.audio.duration),
    ...props.main.map((a) => a.audio!.duration),
  ]);

  return (
    <>
      {!Number.isNaN(total) && showLoader === LoaderValues.Done && (
        <Text styles={TextStyles}>
          Twoja medytacja będzie trwała około {total} minut.
        </Text>
      )}
      {showLoader !== LoaderValues.Done && (
        <Stack horizontalAlign="center">
          <Stack>
            <ReactLoading
              type={"bars"}
              color={"#ffa433"}
              height={"10px"}
              width={"100px"}
            />
          </Stack>
          <Stack styles={{ root: { marginTop: "80px" } }}>
            {showLoader === LoaderValues.GeneratingBackgroundSound && (
              <Text>(1/3) Przygotowywanie dźwięków...</Text>
            )}
            {showLoader === LoaderValues.GeneratingVoice && (
              <Text>(2/3) Generowanie głosu...</Text>
            )}
            {showLoader === LoaderValues.Downloading && (
              <Text>(3/3) Pobieranie medytacji...</Text>
            )}
          </Stack>
        </Stack>
      )}
      {props.main.length > 0 && showLoader === LoaderValues.Done && (
        <Player toggle={toggle} isPlaying={backgroundPlayer.playing} />
      )}
    </>
  );
};

export default Generator;
