import {
  CreateFromPath,
  CreateSilence,
  CreateSilence10s,
  CreateSilence30s,
  CreateSilence5s,
  folder,
  randomNumberInRange,
} from "./GeneratorHelper";
import { AudioModel } from "./../Models";

export const EndingGenerator = (duration: string): AudioModel[] => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence10s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(`${folder}/end/openEyes/eo${randomNumberInRange(1, 3)}.wav`),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence10s()],
      [CreateSilence30s()],
      [CreateSilence30s(), CreateSilence10s(), CreateSilence5s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/end/lastSentence/el${randomNumberInRange(1, 1)}.wav`
    ),
  ];
  return createdVoices;
};
