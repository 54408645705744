import React, { useState } from "react";
import { Stack, PrimaryButton, Checkbox, ITextStyles } from "@fluentui/react";

const FooterSmallStyles: ITextStyles = {
  root: {
    width: "90vw",
    textAlign: "center",
    zIndex: "1",
  },
};

const TextStyles: ITextStyles = {
  root: {
    position: "absolute",
    top: "65px",
  },
};

export interface IPlayerProps {
  toggle(): void;
  isPlaying: boolean;
}

const Player = (props: IPlayerProps) => {
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [showWarning, setShowWarning] = useState<boolean>(true);

  const onChange = React.useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
      checked?: boolean
    ): void => {
      setIsAccepted(!!checked);
    },
    []
  );

  return (
    <Stack horizontalAlign="center">
      {showWarning && (
        <Checkbox
          label="Rozumiem, że niektóre części medytacji mogą nie być wskazane m.in. dla kobiet w ciąży, osób starszych
        czy osób z określonymi schorzeniami zdrowotnymi. Biorę udział w medytacji na własną odpowiedzialność."
          checked={isAccepted}
          styles={{ root: { width: "80vw" } }}
          onChange={onChange}
          required
        />
      )}
      <PrimaryButton
        disabled={!isAccepted}
        onClick={() => {
          setShowWarning(false);
          props.toggle();
        }}
      >
        {props.isPlaying ? "Stop" : "Start"}
      </PrimaryButton>
    </Stack>
  );
};

export default Player;
