import {
  CreateFromPath,
  CreateSilence,
  CreateSilence10s,
  CreateSilence5s,
  folder,
  randomNumberInRange,
} from "./GeneratorHelper";
import { AudioModel } from "./../Models";

export const AffirmationGenerator = (
  duration: string,
  selectedAffirmations: string[]
): AudioModel[] => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence10s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/affirmations/intro/ai${randomNumberInRange(1, 3)}.wav`
    ),
  ];

  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence10s()]
    ),
  ];
  for (let index = 0; index < 3; index++) {
    for (let index = 0; index < selectedAffirmations.length; index++) {
      const element = selectedAffirmations[index];
      createdVoices = [
        ...createdVoices,
        CreateFromPath(`${folder}/affirmations/content/${element}.wav`),
      ];
      createdVoices = [
        ...createdVoices,
        ...CreateSilence(
          duration,
          [CreateSilence5s()],
          [CreateSilence5s()],
          [CreateSilence10s()]
        ),
      ];
    }
  }
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence10s()],
      [CreateSilence10s()],
      [CreateSilence10s()]
    ),
  ];

  return createdVoices;
};
