import React, { useState } from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IChoiceGroupOption,
  ChoiceGroup,
  IImageProps,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

const options: IChoiceGroupOption[] = [
  { key: "short", text: "Raczej krótsza" },
  { key: "medium", text: "Średnia" },
  { key: "long", text: "Raczej dłuższa" },
];

export interface ITimeSelectionProps {
  onNext(timeSelection: string): void;
  step: number;
}

const TimeSelection = (props: ITimeSelectionProps) => {
  const onlyWidth = useWindowWidth();
  const [duration, setDuration] = useState<string>();

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Wybierz czas trwania</Text>
          <Text variant="xLarge">Krok {props.step}</Text>
          <ChoiceGroup
            options={options}
            onChange={(
              ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
              option: IChoiceGroupOption | undefined
            ) => setDuration(option?.key)}
            label="Czas trwania"
            required={true}
          />
          <Stack
            horizontalAlign="center"
            styles={{ root: { paddingBottom: "30px" } }}
          >
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!duration}
              onClick={() => props.onNext(duration || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Wybierz czas trwania</Text>
            <Text variant="xLarge">Krok {props.step}</Text>
            <ChoiceGroup
              options={options}
              onChange={(
                ev: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
                option: IChoiceGroupOption | undefined
              ) => setDuration(option?.key)}
              label="Czas trwania"
              required={true}
            />
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={!duration}
              onClick={() => props.onNext(duration || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default TimeSelection;
