import React, { useState, useEffect } from "react";
import {
  Stack,
  IStackStyles,
  Text,
  PrimaryButton,
  IButtonStyles,
  Image,
  IImageProps,
  Checkbox,
  ITextStyles,
} from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";
import { Affirmation } from "../../Models";
import { sortRandom } from "../../generators/GeneratorHelper";

const imageProps: Partial<IImageProps> = {
  src: "meditation.png",
};

const IntroStackStyles: IStackStyles = {
  root: {
    width: "80vw",
  },
};

const ButtonStackStyles: IButtonStyles = {
  root: {
    width: "200px",
    marginTop: "30px",
  },
};

const ExplanationStyles: ITextStyles = {
  root: {
    marginBottom: "30px",
  },
};

const all: Affirmation[] = [
  { id: "0", value: "Jestem wystarczający" },
  { id: "1", value: "Jestem spokojny" },
  { id: "2", value: "Jestem z siebie zadowolony" },
  { id: "3", value: "Jestem bezpieczny" },
  { id: "4", value: "Jestem tu i teraz" },
  { id: "5", value: "Jestem częścią czegoś większego" },
  { id: "6", value: "Dbam o siebie" },
  { id: "7", value: "Doceniam innych" },
  { id: "8", value: "Doceniam siebie" },
  { id: "9", value: "Mam znaczenie" },
  { id: "10", value: "Kocham życie" },
  { id: "11", value: "Akceptuje swoje niedoskonałości" },
];

export interface ITimeSelectionProps {
  onNext(activitySelection: string[]): void;
  isShort: boolean;
  step: number;
}

const AffirmationSelection = (props: ITimeSelectionProps) => {
  const onlyWidth = useWindowWidth();
  const [affirmations, setAffirmations] = useState<string[]>([]);
  const [affirmationList, setAffirmationList] = useState<Affirmation[]>([]);

  useEffect(() => {
    setAffirmationList(sortRandom(all).slice(0, 10));
  }, []);

  const onChange = React.useCallback(
    (id: string, checked: boolean): void => {
      if (!checked && affirmations.includes(id)) {
        setAffirmations([...affirmations.filter((a) => a !== id)]);
      }
      if (checked && !affirmations.includes(id)) {
        setAffirmations([...affirmations, id]);
      }
    },
    [affirmations]
  );

  return (
    <>
      {onlyWidth <= 600 && (
        <Stack styles={IntroStackStyles}>
          <Text variant="xxLarge">Wybierz afirmacje</Text>
          <Text variant="xLarge">Krok {props.step}</Text>
          <Text styles={ExplanationStyles}>
            Wybierz{" "}
            {props.isShort
              ? "trzy najbliższe Ci afirmacje"
              : "pięć najbliższych Ci afirmacji"}
          </Text>
          <>
            {affirmationList.slice(0, 5).map(({ id, value }) => {
              return (
                <Checkbox
                  key={id}
                  label={value}
                  checked={affirmations.includes(id)}
                  styles={{ root: { width: "200px" } }}
                  onChange={(
                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean
                  ) => onChange(id, checked!)}
                />
              );
            })}
            {affirmationList.slice(5, 10).map(({ id, value }) => {
              return (
                <Checkbox
                  key={id}
                  label={value}
                  checked={affirmations.includes(id)}
                  styles={{ root: { width: "200px" } }}
                  onChange={(
                    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean
                  ) => onChange(id, checked!)}
                />
              );
            })}
          </>
          <Stack
            horizontalAlign="center"
            styles={{ root: { paddingBottom: "30px" } }}
          >
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={
                props.isShort
                  ? affirmations.length !== 3
                  : affirmations.length !== 5
              }
              onClick={() => props.onNext(affirmations || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
      {onlyWidth > 600 && (
        <Stack styles={IntroStackStyles} horizontal>
          <Stack>
            <Image {...imageProps} width="300px" height="auto" />
          </Stack>
          <Stack styles={{ root: { paddingLeft: "100px" } }}>
            <Text variant="xxLarge">Wybierz afirmacje</Text>
            <Text variant="xLarge">Krok {props.step}</Text>
            <Text styles={ExplanationStyles}>
              Wybierz{" "}
              {props.isShort
                ? "trzy najbliższe Ci afirmacje"
                : "pięć najbliższych Ci afirmacji"}
            </Text>
            <Stack horizontal>
              <Stack>
                {affirmationList.slice(0, 5).map(({ id, value }) => {
                  return (
                    <Checkbox
                      key={id}
                      label={value}
                      checked={affirmations.includes(id)}
                      styles={{ root: { width: "200px" } }}
                      onChange={(
                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                        checked?: boolean
                      ) => onChange(id, checked!)}
                    />
                  );
                })}
              </Stack>
              <Stack>
                {affirmationList.slice(5, 10).map(({ id, value }) => {
                  return (
                    <Checkbox
                      key={id}
                      label={value}
                      checked={affirmations.includes(id)}
                      styles={{ root: { width: "200px" } }}
                      onChange={(
                        ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                        checked?: boolean
                      ) => onChange(id, checked!)}
                    />
                  );
                })}
              </Stack>
            </Stack>
            <PrimaryButton
              styles={ButtonStackStyles}
              disabled={
                props.isShort
                  ? affirmations.length !== 3
                  : affirmations.length !== 5
              }
              onClick={() => props.onNext(affirmations || "")}
            >
              Następny krok
            </PrimaryButton>
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default AffirmationSelection;
