import { AudioModel } from "./../Models";

export const folder =
  "66895869-c0ab-449f-912f-e844e8805cd466895869-c0ab-449f-912f-e844e8805cd4";

export const CreateFromPath = (path: string): AudioModel => {
  return {
    url: path,
    audio: new Audio(path),
  };
};

export const CreateSilence5s = (): AudioModel => {
  return {
    url: `${folder}/silence/silence5s.wav`,
    audio: new Audio(`${folder}/silence/silence5s.wav`),
  };
};

export const CreateSilence10s = (): AudioModel => {
  return {
    url: `${folder}/silence/silence10s.wav`,
    audio: new Audio(`${folder}/silence/silence10s.wav`),
  };
};

export const CreateSilence30s = (): AudioModel => {
  return {
    url: `${folder}/silence/silence30s.wav`,
    audio: new Audio(`${folder}/silence/silence30s.wav`),
  };
};

export const randomNumberInRange = (min: number, max: number): number => {
  // 👇️ get number between min (inclusive) and max (inclusive)
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const CreateSilence = (
  duration: string,
  short: AudioModel[],
  medium: AudioModel[],
  long: AudioModel[]
): AudioModel[] => {
  if (duration === "short") {
    return short;
  }
  if (duration === "medium") {
    return medium;
  }
  if (duration === "long") {
    return long;
  }
  return [];
};

export const sortRandom = (arrayToSort: any[]): any[] => {
  let array = [...arrayToSort];
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * i);
    let k = array[i];
    array[i] = array[j];
    array[j] = k;
  }
  return array;
};
