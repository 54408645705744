export enum ViewScreen {
  Intro,
  Time,
  Background,
  BreatheSelection,
  Activity,
  Affirmations,
  BodyParts,
  Intentions,
  End,
}

export enum LoaderValues {
  GeneratingVoice,
  GeneratingBackgroundSound,
  Downloading,
  Done,
}

export interface Affirmation {
  id: string;
  value: string;
}

export interface BodyPart {
  id: string;
  value: string;
}

export interface AudioModel {
  audio?: HTMLAudioElement;
  url: string;
}
