import React from "react";
import Main from "./components/Main";
import { ThemeProvider } from "@fluentui/react";
import { myTheme } from "./theme/theme";

const App = () => {
  return (
    <ThemeProvider theme={myTheme}>
      <Main />
    </ThemeProvider>
  );
};

export default App;
