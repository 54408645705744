import {
  CreateFromPath,
  CreateSilence,
  CreateSilence10s,
  CreateSilence30s,
  CreateSilence5s,
  folder,
  randomNumberInRange,
} from "./GeneratorHelper";
import { AudioModel } from "./../Models";

export const GenerateExerciseBreathe = (
  duration: string,
  isLonger?: boolean
): AudioModel[] => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence10s(), CreateSilence10s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/breathExercise/intro/bi${randomNumberInRange(1, 3)}.wav`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence5s()],
      [CreateSilence5s()]
    ),
  ];
  let numberOfRoundes = duration === "short" ? 2 : isLonger ? 4 : 3;
  for (let index = 0; index < numberOfRoundes; index++) {
    createdVoices = [
      ...createdVoices,
      CreateFromPath(
        `${folder}/breathExercise/round/br${randomNumberInRange(1, 3)}.wav`
      ),
    ];
  }
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/breathExercise/break/bb${randomNumberInRange(1, 2)}.wav`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence10s()],
      [CreateSilence10s(), isLonger ? CreateSilence10s() : CreateSilence5s()],
      [CreateSilence10s(), isLonger ? CreateSilence30s() : CreateSilence10s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/breathExercise/afterBreak/ba${randomNumberInRange(1, 2)}.wav`
    ),
  ];
  for (let index = 0; index < numberOfRoundes; index++) {
    createdVoices = [
      ...createdVoices,
      CreateFromPath(
        `${folder}/breathExercise/round/br${randomNumberInRange(1, 3)}.wav`
      ),
    ];
  }
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/breathExercise/end/be${randomNumberInRange(1, 3)}.wav`
    ),
  ];

  return createdVoices;
};
