import { createTheme } from "@fluentui/react";

export const myTheme = createTheme({
  palette: {
    themePrimary: "#ffa433",
    themeLighterAlt: "#fffbf7",
    themeLighter: "#fff0de",
    themeLight: "#ffe3c2",
    themeTertiary: "#ffc885",
    themeSecondary: "#ffae4b",
    themeDarkAlt: "#e6932e",
    themeDark: "#c27c27",
    themeDarker: "#8f5b1d",
    neutralLighterAlt: "#faf9f8",
    neutralLighter: "#ffc884",
    neutralLight: "#ffffff",
    neutralQuaternaryAlt: "#e1dfdd",
    neutralQuaternary: "#ffffff",
    neutralTertiaryAlt: "#c8c6c4",
    neutralTertiary: "#bba97c",
    neutralSecondary: "#a4905c",
    neutralSecondaryAlt: "#a4905c",
    neutralPrimaryAlt: "#8e7840",
    neutralPrimary: "#362701",
    neutralDark: "#624d17",
    black: "#4c390a",
    white: "#ffffff",
  },
});
