import React, { useState, useEffect } from "react";
import { Stack, IStackStyles } from "@fluentui/react";
import Generator from "./Generator";
import Footer from "./Footer";
import { AudioModel, ViewScreen } from "../Models";
import Intro from "./Intro";
import TimeSelection from "./Selections/TimeSelection";
import BackgroundSelection from "./Selections/BackgroundSelection";
import ActivitySelection from "./Selections/ActivitySelection";
import AffirmationSelection from "./Selections/AffirmationSelection";
import { randomNumberInRange } from "../generators/GeneratorHelper";
import { GenerateBreathe } from "../generators/BreatheGenerator";
import { AffirmationGenerator } from "../generators/AffirmationGenerator";
import BreatheSelection from "./Selections/BreatheSelection";
import { GenerateExerciseBreathe } from "../generators/BreatheExerciseGenerator";
import { IntroGenerator } from "../generators/IntroGenerator";
import { EndingGenerator } from "../generators/EndingGenerator";
import TenseSelection from "./Selections/TenseSelection";
import { TenseGenerator } from "../generators/TenseGenerator";
import IntentionSelection from "./Selections/IntentionSelection";
import { IntensionsGenerator } from "../generators/IntensionsGenerator";

const folder =
  "66895869-c0ab-449f-912f-e844e8805cd466895869-c0ab-449f-912f-e844e8805cd4";

const Main = () => {
  const [currentView, setCurrentView] = useState<ViewScreen>(ViewScreen.Intro);
  const [duration, setDuration] = useState<string>();
  const [background, setBackground] = useState<AudioModel>({ url: "" });
  const [activity, setActivity] = useState<string>();
  const [selectedAffirmations, setSelectedAffirmations] = useState<string[]>(
    []
  );
  const [affirmationsAudio, setAffirmationsAudio] = useState<AudioModel[]>([]);
  const [focusOnBreathAudio, setFocusOnBreathAudio] = useState<AudioModel[]>(
    []
  );
  const [allAudio, setAllAudio] = useState<AudioModel[]>([]);
  const [introAudio, setIntroAudio] = useState<AudioModel[]>([]);
  const [breatheExerciseAudio, setBreatheExerciseAudio] = useState<
    AudioModel[]
  >([]);
  const [breatheSelection, setBreatheSelection] = useState<string>("");
  const [endingAudio, setEndingAudio] = useState<AudioModel[]>([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState<string[]>([]);
  const [tensionAudio, setTensionAudio] = useState<AudioModel[]>([]);
  const [selectedIntention, setSelectedIntention] = useState<string>("");
  const [intentionAudio, setIntentionAudio] = useState<AudioModel[]>([]);
  const [currentStep, setCurrentStep] = useState<number>(1);

  const MainStackStyles: IStackStyles = {
    root: {
      marginTop: "30px",
      minHeight: "calc(100vh - 130px)",
    },
  };

  useEffect(() => {
    if (!!duration && !!activity) {
      setEndingAudio(EndingGenerator(duration));
    }
  }, [activity, duration]);

  useEffect(() => {
    if (!!duration && selectedBodyParts.length > 0) {
      setTensionAudio(TenseGenerator(duration, selectedBodyParts));
    }
  }, [selectedBodyParts, duration]);

  useEffect(() => {
    if (duration) {
      setIntroAudio(IntroGenerator(duration));
    }
  }, [duration]);

  useEffect(() => {
    if (selectedAffirmations.length > 0 && !!duration) {
      setAffirmationsAudio(
        AffirmationGenerator(duration, selectedAffirmations)
      );
    }
  }, [selectedAffirmations, duration]);

  useEffect(() => {
    if (!!breatheSelection && !!duration) {
      if (breatheSelection === "breatheExercise") {
        setBreatheExerciseAudio(
          GenerateExerciseBreathe(duration, duration !== "short")
        );
      } else if (breatheSelection === "breathe") {
        setFocusOnBreathAudio(GenerateBreathe(duration, duration !== "short"));
      } else {
        setBreatheExerciseAudio(GenerateExerciseBreathe(duration));
        setFocusOnBreathAudio(GenerateBreathe(duration));
      }
    }
  }, [breatheSelection, duration]);

  useEffect(() => {
    if (!!selectedIntention && !!duration && selectedIntention !== "without") {
      setIntentionAudio(IntensionsGenerator(duration, selectedIntention));
    }
  }, [selectedIntention, duration]);

  useEffect(() => {
    if (
      currentView === ViewScreen.End &&
      introAudio.length > 0 &&
      ((breatheSelection === "breatheExercise" &&
        breatheExerciseAudio.length > 0) ||
        (breatheSelection === "breathe" && focusOnBreathAudio.length > 0) ||
        (duration !== "short" &&
          focusOnBreathAudio.length > 0 &&
          breatheExerciseAudio.length > 0)) &&
      ((selectedAffirmations.length > 0 && affirmationsAudio.length > 0) ||
        selectedAffirmations.length === 0) &&
      ((activity === "muscleTension" && tensionAudio.length > 0) ||
        activity !== "muscleTension") &&
      ((duration !== "short" && intentionAudio.length > 0) ||
        (duration !== "short" && selectedIntention === "without") ||
        duration === "short")
    ) {
      let activities: AudioModel[] = [];
      if (duration === "short") {
        if (randomNumberInRange(0, 1) === 0) {
          activities = [
            ...focusOnBreathAudio,
            ...breatheExerciseAudio,
            ...affirmationsAudio,
            ...tensionAudio,
          ];
        } else {
          activities = [
            ...affirmationsAudio,
            ...tensionAudio,
            ...focusOnBreathAudio,
            ...breatheExerciseAudio,
          ];
        }
      } else {
        if (randomNumberInRange(0, 1) === 0) {
          activities = [
            ...focusOnBreathAudio,
            ...tensionAudio,
            ...affirmationsAudio,
            ...breatheExerciseAudio,
            ...intentionAudio,
          ];
        } else {
          activities = [
            ...breatheExerciseAudio,
            ...tensionAudio,
            ...affirmationsAudio,
            ...focusOnBreathAudio,
            ...intentionAudio,
          ];
        }
      }
      setAllAudio([...introAudio, ...activities, ...endingAudio]);
    }
  }, [
    currentView,
    introAudio,
    focusOnBreathAudio,
    tensionAudio,
    affirmationsAudio,
    intentionAudio,
    duration,
  ]);

  return (
    <>
      <Stack
        styles={MainStackStyles}
        horizontalAlign="center"
        verticalAlign="center"
      >
        {currentView === ViewScreen.Intro && (
          <Intro onNext={() => setCurrentView(ViewScreen.Time)} />
        )}
        {currentView === ViewScreen.Time && (
          <TimeSelection
            onNext={(value: string) => {
              setCurrentView(ViewScreen.Background);
              setDuration(value);
              setCurrentStep(currentStep + 1);
            }}
            step={currentStep}
          />
        )}
        {currentView === ViewScreen.Background && (
          <BackgroundSelection
            step={currentStep}
            onNext={(value: string) => {
              setCurrentView(ViewScreen.BreatheSelection);
              setCurrentStep(currentStep + 1);
              setBackground({
                url: `${folder}/backgrounds/${value}`,
                audio: new Audio(`${folder}/backgrounds/${value}`),
              });
            }}
          />
        )}
        {currentView === ViewScreen.BreatheSelection && (
          <BreatheSelection
            step={currentStep}
            onNext={(value: string) => {
              setCurrentView(ViewScreen.Activity);
              setCurrentStep(currentStep + 1);
              setBreatheSelection(value);
            }}
            duration={duration!}
          />
        )}
        {currentView === ViewScreen.Activity && (
          <ActivitySelection
            step={currentStep}
            isShort={duration === "short"}
            onNext={(value: string) => {
              setCurrentView(
                value === "affirmations"
                  ? ViewScreen.Affirmations
                  : ViewScreen.BodyParts
              );
              setCurrentStep(currentStep + 1);
              setActivity(value);
            }}
          />
        )}
        {currentView === ViewScreen.Affirmations && (
          <AffirmationSelection
            step={currentStep}
            isShort={duration === "short"}
            onNext={(value: string[]) => {
              setCurrentView(
                duration === "short" ? ViewScreen.End : ViewScreen.Intentions
              );
              setCurrentStep(currentStep + 1);
              setSelectedAffirmations(value);
            }}
          />
        )}
        {currentView === ViewScreen.BodyParts && (
          <TenseSelection
            step={currentStep}
            isShort={duration === "short"}
            onNext={(value: string[]) => {
              setCurrentView(
                duration === "short" ? ViewScreen.End : ViewScreen.Intentions
              );
              setCurrentStep(currentStep + 1);
              setSelectedBodyParts(value);
            }}
          />
        )}
        {currentView === ViewScreen.Intentions && (
          <IntentionSelection
            step={currentStep}
            onNext={(value: string) => {
              setCurrentView(ViewScreen.End);
              setSelectedIntention(value);
              setCurrentStep(currentStep + 1);
            }}
          />
        )}
        {currentView === ViewScreen.End && allAudio.length > 0 && (
          <Generator main={[...allAudio]} background={background} />
        )}
      </Stack>
      <Footer />
      <Stack></Stack>
    </>
  );
};

export default Main;
