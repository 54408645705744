import React from "react";
import { Stack, Image, Text, ITextStyles } from "@fluentui/react";
import { useWindowWidth } from "@react-hook/window-size";

const FooterSmallStyles: ITextStyles = {
  root: {
    width: "90vw",
    textAlign: "center",
    zIndex: "1",
  },
};

const TextStyles: ITextStyles = {
  root: {
    position: "absolute",
    top: "65px",
  },
};

const Footer = () => {
  const onlyWidth = useWindowWidth();

  return (
    <Stack
      horizontalAlign="center"
      styles={{
        root: {
          position: "relative",
          backgroundImage: `url(${onlyWidth > 600 ? "tlo1.png" : "tlo2.png"})`,
          backgroundSize: "100% 100%",
          height: "100px",
          marginTop: `${onlyWidth > 600 ? "0" : "20px"}`,
        },
      }}
    >
      <Text styles={TextStyles}>Copyright © 2023 Kamil Bugno</Text>
      {/* <Text variant="xSmall" styles={FooterSmallStyles}>
        Niektóre części medytacji takie jak ćwiczenia oddechowe czy napinanie
        mięśni może nie być wskazane m.in. dla kobiet w ciąży, osób starszych
        czy osób z określonymi schorzeniami zdrowotnymi. Jeśli masz wątpliwości,
        skontaktuj się z lekarzem przed rozpoczęciem medytacji. Twórca strony
        nie ponosi odpowiedzialności za jakiekolwiek efekty przeprowadzonych
        ćwiczeń medytacyjnych. Ćwiczenia nie są formą działalności leczniczej i
        użytkownik strony (Ty) bierze w nich udział na własną odpowiedzialność.
      </Text> */}
    </Stack>
  );
};

export default Footer;
