import {
  CreateFromPath,
  CreateSilence,
  CreateSilence10s,
  CreateSilence5s,
  folder,
  randomNumberInRange,
} from "./GeneratorHelper";
import { AudioModel } from "./../Models";

export const IntroGenerator = (duration: string): AudioModel[] => {
  let createdVoices: AudioModel[] = [];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/intro/beginning/w${randomNumberInRange(1, 3)}.mp3`
    ),
  ];
  createdVoices = [
    ...createdVoices,
    ...CreateSilence(
      duration,
      [CreateSilence5s()],
      [CreateSilence10s()],
      [CreateSilence10s()]
    ),
  ];
  createdVoices = [
    ...createdVoices,
    CreateFromPath(
      `${folder}/intro/closeEyes/bri${randomNumberInRange(1, 2)}.wav`
    ),
  ];
  return createdVoices;
};
